<template>
  <div>
    <v-card
      color="#26c6da"
      dark
      tile
    >
      <div class="text-center py-4">
        <v-row>
          <v-col>
            <div class="text-h5">{{ $store.state.userProfile.totalPoints }}</div>
            <div class="title">{{ $t("available_points") }}</div>
          </v-col>
          <v-col>
            <div class="text-h5">{{ treesPlanted }}</div>
            <div class="title">{{ $t("trees_planted") }}</div>
          </v-col>
        </v-row>
      </div>
    </v-card>

    <center class="title py-4">
      <v-row class="py-4">
        <v-col cols="12" class="pa-0 ma-0">
          <img src="./../../assets/images/plant_tree.png" v-on:click="$router.push({ name: 'PlantTree', params: { cashoutPlantTreeAvailable } })" width="100" height="100" />
        </v-col>
        <v-col cols="12" class="pa-0 ma-0">
          {{ $t("plant_tree") }}
        </v-col>
      </v-row>

      <!-- <v-row class="py-4">
        <v-col cols="12" class="pa-0 ma-0">
          <img src="./../../assets/images/cashout_bank.png" v-on:click="$router.push({ name: 'RedeemBank', params: { cashoutBankAvailable } })" width="100" height="100" />
        </v-col>
        <v-col cols="12" class="pa-0 ma-0">
          {{ $t("cashout_to_bank") }}
        </v-col>
      </v-row> -->

      <v-row class="py-4">
        <v-col cols="12" class="pa-0 ma-0">
          <img src="./../../assets/images/cashout_paypal.png" v-on:click="$router.push({ name: 'RedeemPaypal', params: { cashoutPaypalAvailable } })" width="100" height="100" />
        </v-col>
        <v-col cols="12" class="pa-0 ma-0">
          {{ $t("cashout_to_paypal") }}
        </v-col>
      </v-row>
    </center>

  </div>
</template>

<script>
import { DataService } from "../../services/DataService";

const dataService = new DataService();

export default {
  data() {
    return {
      treesPlanted: 0,
      cashoutBankAvailable: false,
      cashoutPaypalAvailable: false,
      cashoutPlantTreeAvailable: false,
    }
  },
  mounted() {
    this.getTreesPlanted()
  },
  methods: {
    getTreesPlanted() {
      this.$store.state.loader = true
      dataService.checkRewardInfo(this.$store.state.token, { userId: this.$store.state.userProfile._id }).then(res => {
        this.$store.state.loader = false
        this.treesPlanted = res.treesPlanted
        this.cashoutBankAvailable = res.cashoutBankAvailable
        this.cashoutPaypalAvailable = res.cashoutPaypalAvailable,
        this.cashoutPlantTreeAvailable = res.cashoutPaypalAvailable
      })
    }
  }
}
</script>
<style scoped>
.imageContainer {
  margin-top: 20%
}
.logoStyle{
  height: 300px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>